
$(document).ready(function () {

    var slideOut = new Slideout({
        'panel': document.getElementById('slideout-panel'),
        'menu': document.getElementById('slideout-menu'),
        'padding': 275,
        'tolerance': 70,
        'side': 'right'
    });
    activateMenuSlideOut(slideOut);

});


function activateMenuSlideOut(slideOut) {

    $('.toggle-button').on('click', function () {
        slideOut.toggle();
        slideOut.enableTouch();
    });

    $('.mobile-back').on('click', function () {
        slideOut.close();
    });

    $('.disable--mobile--menu').on('click', function () {
        slideOut.disableTouch();
    });

    slideOut.on('beforeopen', function () {
        $(".slidein-darkness").addClass('slideout-panel-darkness');
        $(".Masthead").css("position", "absolute");
        $(".Masthead").css("top", $(window).scrollTop());
    });

    slideOut.on('beforeclose', function () {
        $(".slidein-darkness").removeClass('slideout-panel-darkness');
        $(".Masthead").css("top", "inherit");
        $(".Masthead").css("position", "fixed");
    });

}