(function ($) {

    var $contactForms = $('.Contact-form');
    if($contactForms.size() == 0) {
        return;
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function resetForm($form) {
        // Remove possible error changes to the form.
        $form.removeClass('has-error');
        $form.find('.Contact-form__help-block').hide();
    }

    function onSuccess(data) {

        var $contactForm = this.$contactForm;
        
        // Show the alert with the success message.
        var alert = $contactForm.find('.Contact-form__success-message');
        alert.show();

        // Hide the input part of the contact form.
        var form = $contactForm.find('.Contact-form__form');
        form.hide();
    }

    function onError(result) {

        var $contactForm = this.$contactForm;

        // Remove any possible previous errors or messages.
        resetForm($contactForm);

        // Parse the response.
        var response = JSON.parse(result.responseText);

        // Set the form state to 'has-error'.
        $contactForm.addClass('has-error');

        // Show all error messages.
        $.each(response, function (key, messages) {
            var errorBlock = $contactForm.find('.Contact-form__help-block--' + key);
            var messagesString = "";
            for(var i in messages) {
                messagesString += capitalizeFirstLetter(messages[i]) + '\n\r';
            }
            messagesString = messagesString.slice(0, -1);
            errorBlock.text(messagesString);
            errorBlock.show();
        });
    }

    $contactForms.submit(function (evt) {

        var $this = $(this);

        // Get the information we need to send the request.
        var url     = $this.attr('action');
        var method  = $this.attr('method') || 'GET';
        var data    = $this.serialize();

        // Execute the request.
        $.ajax({
            type:           method,
            url:            url,
            data:           data,
            dataType:       'json',
            $contactForm:   $this,
            success:        onSuccess,
            error:          onError
        });

        // Prevent the actual execution of the form.
        evt.preventDefault();
    });

})(jQuery);