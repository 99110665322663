(function ($) {

    var $window = $(window);

    // Get all containers with animated images.
    var $containers = $('.AnimatedImage');

    function animateImages() {

        // Get some information about the scrolling position.
        var windowTop = $window.scrollTop();
        var windowHeight = $window.height();

        // Animate all containers.
        $containers.each(function() {

            var $container = $(this);

            // Get the number of frames.
            var $images = $container.children('.AnimatedImage__image');
            var numFrames = $images.size() - 1;

            // Get the area over which the animation should happen.
            var containerStart = $container.offset().top;
            var containerHeight = $container.height();

            // Calculate the current frame.
            var progressOfAnimation = (containerStart - windowTop) / (windowHeight - containerHeight);
            progressOfAnimation = Math.min(Math.max(progressOfAnimation, 0), 1);
            
            var currentFrame = Math.round(progressOfAnimation * numFrames);

            // Hide all images except the new current frame.
            $images.hide();
            $images.eq(currentFrame).show();

        });

    }

    // Make sure the correct image is visible and the others are not.
    animateImages();

    // Create a scroll event to be able to actually animate the images.
    $window.scroll(animateImages);

})(jQuery);